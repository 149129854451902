@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home, .mechanism , .IDO , .staking, .lpstaking, .community, .roast, .CARD {
  /* background-image:url(https://cdn.mos.cms.futurecdn.net/owm4oa3hXUG58mka7hPvfP.jpg); */
  background-image:url(./Pages/assets/IDOBGPIC5.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.App {
  position: relative;
  background-color: black;
  z-index: 0;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url(./BG_IDO.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#connect-btn{
  font-family: 'Play', sans-serif;
  color: black;
  flex: 2;
  position: fixed;
  top: 20px;
  right: 40px;
  background-color:wheat; 
  border-radius:30px;
  width: 80px;
  height: 40px;
  border: 2px solid;
  border-color:black;
  position: absolute;
}


#balance-btn{
  font-family: 'Play', sans-serif;
  color: black;
  position: fixed;
  top: 20px;
  right: 160px;
  background-color:wheat; 
  width: 80px;
  height: 40px;
  border: 2px solid;
  border-color:black;
  position: absolute;
}

.hr1{
  color: black;
  position: fixed;
  top: 100px;
  right: 0px;
  width: 2000px;
  border: 2px solid;
}

#isConnected{
  position: fixed;
  top: 0px;
  left: 30px;
  color: red;
  flex: 1;  
  justify-content: center; 
}

.button{
  justify-content: center; 
  align-items: center; 
}

table {    
  text-align: center;
  font-family: 'Playfair Display', serif;
}

.Ryo {
  text-decoration: none;
  color: blue;
}